$navcolor : #54595f;
$navactivecolor: #0f54c3;
$btncolor: #0ab67b;
$colorwhite: #fff;
$defaultsectionpadding: 4.5% 0%;
$bordercolor: #7a7a7a;
$lightbgcolor: #e4ecff;
$footerbg: #032e82;

.pagewidth {
    // width: 80%;
    width: 1300px;
    margin: 0px auto;
    display: block;
}
a:focus, a:active {
    text-decoration: none !important;
}
p {
    margin-bottom: 0px !important;
    font-family: 'Montserrat', sans-serif;
}
.headerdiv {
    padding: 15px 0px;
    position: relative;
    z-index: 11;
}
.mainlog {
    height: 68px;
}
.headerblock {
    display: flex;
    justify-content: space-between;
}
.navbar a {
    color: $navcolor;
    margin: 0px 15px;
    text-decoration: none;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
}
.navbar a.active {
    color: $navactivecolor;
}
.navbar a:hover {
    color: $navactivecolor;
    text-decoration: none;
}
.navbar-nav {
    flex-direction: row !important;
}
.headerdiv .navbar {
    height: 72px;
}
.header-registernow-btn {
    background-color: $btncolor;
    padding: 10px 24px;
    font-size: 15px;
    font-weight: 700;
    height: max-content;
    border-radius: 5px;
    margin-top: 13px;
    cursor: pointer;
}
.header-registernow-btn p {
    color: $colorwhite;
    font-size: 16px;
}
.bannermaindiv {
    background-image: url('../assets/banner/1.png');
    background-size: cover;
    height: 80vh;
    background-position: center center;
}
.banneroverlaycolor {
    background: linear-gradient(to right, rgba(15,84,195,0.39), rgba(3,46,190,0.51));
    height: 80vh;
    position: relative;
}
.empowering-div {
    position: absolute;
    bottom: 150px;
}  
.empowering-head {
    color: $colorwhite;
    font-size: 44px;
    font-weight: 600;
}
.empowering-desc {
    color: $colorwhite;
    margin-top: 30px;
    font-size: 19px;
    font-weight: 600;
    width: 60%;
}
.banner-btn-div {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
}
.course-btn {
    background-color: $btncolor;
    color: $colorwhite;
    border: 1px solid $btncolor;
    padding: 11px 28px;
    font-size: 16px;
    font-weight: 700;
    height: max-content;
    border-radius: 10px;
    text-decoration: none;
}
.course-btn p:hover {
    text-decoration: none;
}
.course-btn1 {
    margin-left: 30px;
    background-color: $colorwhite;
    border: 1px solid $colorwhite;
    color: $navactivecolor;
}
.course-btn:hover {
    background-color: $colorwhite;
    color: $btncolor;
    text-decoration: none;
}
.course-btn1:hover {
    background-color: $navactivecolor;
    color: $colorwhite;
}
.home-section2 {
    padding: $defaultsectionpadding;
}
.section-head {
    color: $navactivecolor;
    font-size: 27px;
    text-align: center;
    font-weight: 800;
}
.course-card-row {
    margin-top: 2% !important;
}
.course-card-body {
    box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.5);
    padding: 20px;
    border-radius: 20px;
    width: 100%;
    margin-top: 30px;
}
.course-card-title {
    color: $navactivecolor;
    font-weight: 600;
    font-size: 17px;
    line-height: 25px;
    text-align: center;
    height: 50px;
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.desc-maindiv {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
}
.iconstyles {
    color: $navactivecolor;
    height: 17px;
}
.course-card-desc {
    color: $navcolor;
    margin-left: 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
}
.icondiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.features-div {
    margin-top: 10px;
}
.features1-div {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}
.features1-div .iconstyles {
    height: 14px;
}
.userchecktext {
    margin-left: 8px;
    color: $navcolor;
    font-size: 13px;
    font-weight: 600;
    margin-top: -3px;
}
.course-card-hr {
    margin: 20px 0px;
    border-top: 2px solid $bordercolor;
}
.allcourses-banner-btn-div {
    margin-top: 20px;
}
.course-card-body .course-btn {
    padding: 9px 16px;
    font-size: 15px;
}
.course-card-body .course-btn1 {
    margin-left: auto;
    background-color: $navactivecolor;
    color: $colorwhite;
    border: 1px solid $navactivecolor;
}
.course-card-body .course-btn1:hover {
    background-color: $colorwhite;
    color: $navactivecolor;
}
.course-btn2 {
    display: block;
    margin: 0px auto;
    margin-top: 5%;
    background-color: $navactivecolor;
    color: $colorwhite;
    border: 1px solid $navactivecolor;
    width: max-content;
}
.course-btn2:hover {
    background-color: $colorwhite;
    color: $navactivecolor;
}
.home-section3 {
    padding: $defaultsectionpadding;
}
.light-bg {
    background-color: $lightbgcolor;
}
.imprintsdiv {
    margin: 0px auto;
    display: block;
    margin-top: 40px;
    width: 80%;

}
.imprintsinglediv {
    margin-top: 15px;
}
.imprintCount {
    color: $navactivecolor;
    font-weight: 600;
    font-size: 34px;
    text-align: center;
}
.imprintText {
    color: $navcolor;
    font-weight: 600;
    font-size: 17px;
    text-align: center;
    margin-top: 5px;
}
.home-section4 {
    padding: $defaultsectionpadding;
}
.why-upskills-div {
    margin-top: 30px;
}
.chooseimg {
    width: 90%;
}
.choose-title {
    color: $navactivecolor;
    font-weight: 600;
    font-size: 21px;
    margin-top: 25px;
}
.choose-description {
    margin-top: 15px;
    color: $navcolor;
    font-weight: 600;
    font-size: 16px;
}
.home-section5 {
    padding: $defaultsectionpadding;
}
.trainersmaindiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 30px;
}
.trainer-img1 {
    height: 180px;
    width: 180px;
    border-radius: 10px;
}
.trainer-name {
    color: $navactivecolor;
    text-align: center;
    font-size: 20px;
    margin-top: 15px;
    font-weight: 600;
}
.trainerdiv {
    margin: 20px 40px 0px;
}
.home-section6 {
    padding: $defaultsectionpadding;
}
.testitext {
    font-size: 16px;
    text-align: center;
    color: '#000';
    font-style: italic;
}
.testidiv {
    width: 80%;
    margin: 0px auto;
    display: block;
    margin-top: 40px;
}
.testiname {
    text-align: center;
    font-size: 24px;
    color: $navcolor;
    font-weight: 700;
    margin-top: 30px;
}
.testicountry {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: $navactivecolor;
    margin-top: 5px;
}
.footermaindiv {
    padding: $defaultsectionpadding;
    background-color: $footerbg;
    border-top: 5px solid $btncolor;
    padding-bottom: 0px;
}
.footerinnermaindiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.footerhead {
    color: $colorwhite;
    font-size: 18px;
    font-weight: 600;
}
.footerlinksdiv {
    margin-top: 25px;
}
.footerlinksdiv a {
    display: block;
    color: $colorwhite;
    font-size: 15px;
    opacity: 0.8;
    margin-top: 10px;
    text-decoration: none;
}
.payment-strip img {
    width: 100%;
}
.footericons {
    margin-right: 15px;
}
.copyrightdiv {
    margin-top: 100px;
    border-top: 1px solid rgba(255,255,255,0.5);
}
.copyrighttext {
    padding: 30px 0px !important;
    text-align: center;
    color: $colorwhite;
    font-size: 14px;
}
.headerdiv.is-sticky {
    padding: 4px 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 101;
    background-color: $colorwhite;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); /* Horizontal offset, Vertical offset, Blur radius, Color */
}
.headerdiv.is-sticky .mainlog {
    height: 60px;
    margin-top: 5px;
}
.headerdiv.is-sticky .navbar a {
    font-size: 16px;
}
.headerdiv.is-sticky .header-registernow-btn p {
    font-size: 14px;
}
.breadscrumbg {
    padding: $defaultsectionpadding;
    background: linear-gradient(to right,#032E82, #0F54C3,);
}
.breadscrumb-pagetitle {
    color: $colorwhite;
    font-size: 24px;
    font-weight: 600;
    border-bottom: 3px solid $btncolor;
    width: max-content;
}
.breadscrumb-text {
    color: $colorwhite;
    font-size: 34px;
    font-weight: 600;
    margin-top: 20px;
}
.about-who-we-are {
    text-align: left;
}
.about-us-description {
    width: 80%;
}
.about-us-img {
    width: 100%;
}
.about-left-div {
    background-color: #E8F5FE;
    border-radius: 10px;
    padding: 40px;
    padding-bottom: 60px;
}
.about-right-div {
    background-color: #F1FDE9;
}
.vision-subtext {
    font-size: 21px;
    text-align: center;
    color: $navactivecolor;
    font-weight: 600;
    width: 90%;
    margin: 0px auto;
    display: block;
    margin-top: 15px;
}
.mission-head {
    color: $navcolor;
}
.mission-subtext {
    color: $navcolor;
}
.about-why-subhead {
    font-size: 21px;
    color: '#000';
    font-weight: 600;
}
.about-why-subdiv {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
}
.abouticons {
    height: 30px;
    width: 30px;
    margin-right: 15px;
}
.about-img2 {
    width: 100%;
    margin-top: 80px;
}
.contact-info-div1 {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
}
.contacticons {
    width: 17px;
    height: 17px;
    color: $btncolor;
}
.contactnumber {
    margin-left: 10px;
    font-size: 16px;
    color: #54595f;
    text-decoration: none;
    font-weight: 600;
    margin-top: -2px;
}
.contact-form {
    margin-top: 10px;
}
.contactinput {
    height: 42px;
    border: 1px solid $navcolor !important;
}
.input-div {
    margin-top: 12px;
    width: 90%;
}
.textareainput {
    height: unset;
}
.submit-btn {
    height: 42px;
    width: 100%;
    background-color: $btncolor;
    border: 1px solid $btncolor !important;
    color: $colorwhite;
    border-radius: 5px;
}
.corporate-subtext {
    line-height: 42px;
    margin-top: 30px;
}
.corporate-breadscrumb-desc {
    margin-top: 30px;
    font-size: 16px;
    color: $colorwhite;
}
.corporate-img1 {
    width: 100%;
}
.corporate-section1-head {
    line-height: 36px;
    text-align: left;
}
.corporate-section1-description {
    margin-top: 25px;
    width: 90%;
}
.client-div {
    // padding: 40px 0px;
    border: 1px solid #000;
    border-radius: 10px;
    margin-top: 25px;
    height: 120px;
    display: flex;
    justify-content: center;
}
.client1img {
    max-height: 30px;
    max-width: 60%;
    margin: 0px auto;
    display: block;
    align-self: center;
}
.clientmaindiv {
    margin-top: 20px !important;
}
/* Style for the dropdown container */
.dropdown {
    position: relative;
    display: inline-block;
  }

  /* Style for the dropdown button */
  .dropdown-button {
    background-color: #4CAF50;
    color: white;
    padding: 10px;
    border: none;
    cursor: pointer;
  }

  /* Style for the dropdown content */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: max-content;
    padding: 10px 0px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 11;
  }
  .dropdown-content .dropdown-mainlist {
    padding: 0px 20px;
    // border-bottom: 1px solid $bordercolor;
    color: $navcolor;
    font-size: 15px;
    position: relative;
  }

  /* Style for the dropdown links */
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  /* Style for the dropdown links on hover */
  .dropdown-content a:hover {
    background-color: #f1f1f1;
  }

  /* Show the dropdown content when hovering over the dropdown button */
  .dropdown:hover .dropdown-content {
    display: block;
  }

  .headericons {
    margin-left: 8px;
    height: 15px;
    width: 15px;
    position: relative;
    top: 1px;
  }

  .headericons1 {
    margin-left: 20px;
    height: 15px;
    width: 15px;
    position: relative;
    float: right;
    top: 3px;
  }

  .subdropdowncontent {
    display: none;
    background-color: #fff;
    min-width: max-content;
    padding: 10px 20px;
    position: absolute;
    left: 330px;
    top: 0px;
    z-index: 1;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

.dropdown-mainlist:hover .subdropdowncontent {
    display: block;
}
.subdropdowncontentrow {
    padding: 10px 0px;
    border-bottom: 1px solid $bordercolor;
    color: $navcolor;
    font-size: 15px;
}
.subdropdowncontent .subdropdowncontentrow:last-child {
    border-bottom: 0px solid $bordercolor;
}
.dropdown-content .dropdown-mainlist .dropdown-mainlist-p {
    padding: 10px 0px;
    border-bottom: 1px solid $bordercolor;
    font-weight: 600;
}
.dropdown-mainlist-p:hover {
    color: $navactivecolor;
}
.dropdown-content .dropdown-mainlist:last-child .dropdown-mainlist-p {
    border-bottom: 0px solid $bordercolor;
}
.subdropdowncontentrow {
    cursor: pointer;
}
.subdropdowncontentrow p {
    font-weight: 600;
}
.subdropdowncontentrow p:hover {
    color: $navactivecolor;
}
.course-details-topborder {
    height: 20px;
    background-color: $navactivecolor;
}
.coursedetails-coursetitle {
    text-align: left;
}
.coursedetails-section1 {
    padding: 3% 0%;
}
.section1-rating-div {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
}
.coursedetails-googlediv {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
}
.course-detail-img1 {
    width: 100%;
}
.course-detail-img2{
    height: 35px;
}
.coursedetails-description {
    margin-top: 20px;
    line-height: 28px;
}
.ratinggooglediv {
    margin-left: 15px;
    font-size: 26px;
}
.coursedetails-googlediv1 {
    margin-left: 40px;
}
.coursedetailsnavbar {
    background-color: $navactivecolor;
    padding: 12px 0px;
    position: sticky;
    top: 80px;
    z-index: 10;
}
.coursedetails-navlink a {
    color: $colorwhite;
    font-size: 16px;
    margin: 0px 25px;
}
.coursedetails-navlink:first-child a {
    margin-left: 0px;
}
.coursedetails-navlink a:hover {
    color: $btncolor;
}
.coursedetails-featuresbg {
    margin-top: 30px;
    background-color: #A4C8FF47;
    padding: 20px 30px 35px;
}
.coursedetailsfeatures-row {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
}
.coursedetailsfeatures-row .coursedetailsfeatures-desc {
    margin-top: 0px;
    position: relative;
    top: -3px;
}
.coursedetailsfeatures-desc {
    font-size: 15px;
}
.coursedetailsicons {
    color: $navactivecolor;
    margin-right: 10px;
    font-size: 15px;
}
.features-right-div {
    box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0.5);
    padding: 50px 20px;
    position: sticky;
    top: 100px;
}
.whocanattenddiv {
    background-color: $btncolor;
    width: 70%;
    margin: 0px auto;
    padding: 10px 0px;
    border-radius: 5px;
}
.whocanattenddiv p {
    text-align: center;
    color: $colorwhite;
    font-weight: 600;
    font-size: 15px;
}
.coursedetails-whicanattend {
    font-size: 15px;
    margin-top: 30px;
    line-height: 28px;
}
.coursedetails-whicanattend-pointsidv {
    // margin-top: 30px;
    margin-top: 0px;
}
.downloadbrochurebtn {
    padding: 10px 30px;
    width: max-content;
    margin: 0px;
    margin-top: 20px;
    cursor: pointer;
}
.coursedetails-modulesmaindiv {
    margin-top: 30px;
}
.coursedetails-card {
    border: 1px solid #d5d8dc;
    border-radius: 0px;
}
.coursedetails-cardheader {
    padding: 15px 20px;
    display: flex;
    flex-direction: row;
}
.coursedetails-cardheader p {
    color: $navactivecolor;
    font-size: 17px;
    font-weight: 600;
}
.coursedetails-cardbody {
    padding: 15px 20px;
    border-top: 1px solid #d5d8dc;
}
.coursedetails-cardbody p {
    line-height: 28px;
    margin-top: 0px;
    margin-left: 0px;
}
.coursedetailscardicons {
    color: #000;
    margin-right: 15px;
    position: relative;
    top: 4px;
    font-size: 15px;
    font-weight: 600;
}
.coursedetails-section5 {
    background-image: linear-gradient(300deg, #809ADE 27%, $navactivecolor 100%);
}
.coursedetails-batcheshead {
    color: $colorwhite;
}
.coursedetails-batchessubhead {
    text-align: center;
    color: $colorwhite;
    margin-top: 30px;
}
.coursedetails-batchesdiv {
    margin-top: 50px;
}
.batches-boxdiv {
    background-color: #fff;
    padding: 30px 0px;
    height: 100%;
    position: relative;
    padding-bottom: 200px;
}
.adjust_bottom_schedulediv {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    width: 100%;
    padding: 0px 30px;
    left: 0px;
    bottom: 0px;
}
.inpersonbtn {
    width: max-content;
    padding: 10px 30px;
}
.inpersonbtn p {
    font-size: 17px;
}
.inpersonpointsdiv {
    padding: 0px 30px;
}
.inperson-pricediv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 50px;
}
.cancelledprice {
    color: $navcolor;
    font-weight: 600;
    font-size: 21px;
}
.offerprice {
    margin-left: 5px;
    color: $navactivecolor;
    font-size: 25px;
    font-weight: 600;
}
.inperson-registerbtn {
    padding: 10px 40px;
    background-color: $navactivecolor;
    border-radius: 5px;
    width: max-content;
    margin: 0px auto;
    margin-top: 30px;
    margin-bottom: 50px;
    cursor: pointer;
}
.inperson-registerbtn p {
    color: $colorwhite;
}
.coursedetails-testimonialdiv {
    width: 50%;
    margin: 0px auto;
    display: block;
}
.coursedetails-testiname {
    color: #1c2022;
    font-size: 24px;
    font-weight: 600;
}
.coursedetails-testicountry {
    color: #697882;
    font-size: 15px;
    margin-top: 5px;
}
.coursedetails-desc {
    font-weight: 500;
    font-size: 14px;
}
.coursedetails-section6 {
    background-image: linear-gradient(180deg, #DFEFF8 0%, #92BCFF 100%);
}
.corportegroup {
    margin-top: 30px;
    padding: 15px 30px;
}
.corporateicon {
    margin-right: 10px;
}
.webloginBox {
    width: 30%;
    margin: 0px auto;
    display: block;
    padding: 25px 30px;
    border-radius: 15px;
    box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.2);
    margin-top: 5%;
}
.loginBoxMaindiv {
    height: 100vh;
}
.webcustom-btn {
    background-color: $btncolor !important;
    margin-top: 10px;
    width: 100%;
    border: 1px solid $btncolor !important;
    height: 42px;
}
.webloginBox img {
    margin: 0px auto;
    display: block;
    height: 70px ;
}
.rai-spinner  {
    margin: 0px auto;
    display: block;
}
.sidebar-div {
    display: inline-block;
    height: 100%;
    position: fixed;
    width: 20%;
    z-index: 1;
}
.sidebar-main {
    height: 100vh;
    padding: 50px 20px;
}
.webadmin-sidebarmain {
    background-color: $lightbgcolor;
}
.webadmin-sidebarmain .sidebarLogo {
    display: block;
    width: 70%;
}
.webadmin-sidebarmain .sidebarMenuDiv {
    margin-top: 20px;
    height: 500px;
    padding-bottom: 30px;
}
.sidebarMenuDiv ul {
    list-style: none;
    padding-left: 0;
}
.sidebarMenuDiv li {
    color: #000;
    font-size: 32px;
    font-weight: 700;
    line-height: 35px;
    margin: 30px 0;
    text-align: left;
}
.webadmin-sidebarmain .sidebarMenuDiv li {
    border-bottom: 1px solid #000;
    margin: 0;
    padding: 5px 0;
}
.webadmin-sidebar-maintitlediv {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.webadmin-sidebar-maintitle {
    // font-family: Source Sans Pro;
    font-size: 17px;
    font-weight: 500;
}
.webadmin-sidebar-maintitlediv img {
    height: 15px;
    margin-top: 8px;
}
.sidebarMenuDiv li a {
    color: #000;
    text-decoration: none;
}
/* Custom scrollbar styles */
.webadmin-sidebarmain .sidebarMenuDiv::-webkit-scrollbar {
    width: 5px; /* Width of the scrollbar */
  }
  
  .webadmin-sidebarmain .sidebarMenuDiv::-webkit-scrollbar-track {
    background: #333; /* Color of the scrollbar track */
    border: 1px solid #333;
  }
  
  .webadmin-sidebarmain .sidebarMenuDiv::-webkit-scrollbar-thumb {
    background-color: rgba(255,255,255,.6); /* Color of the scrollbar thumb */
    border-radius: 5px; /* Rounded corners */
    border: 0px solid rgba(255,255,255,.5); /* Border around the thumb */
  }
  
  .webadmin-sidebarmain .sidebarMenuDiv::-webkit-scrollbar-thumb:hover {
    background: #333; /* Color when hovering over the scrollbar thumb */
  }
  .webadmin-sidebarmain .sidebarMenuDiv{
    overflow-y: hidden;
}
.webadmin-sidebarmain .sidebarMenuDiv:hover {
    overflow-y: auto;
}
.webadmin-sidebarmain .dropdown-container {
    background-color: initial;
    padding-left: 20px;
    padding-top: 0;
}
.webadmin-sidebar-subtitle {
    border-bottom: .5px solid #000;
    display: block;
    // font-family: Source Sans Pro;
    font-size: 16px;
    font-weight: 500;
    padding: 4px 0;
}
.dropdown-container .webadmin-sidebar-subtitle:last-child {
    border-bottom: 0;
}
.main-layout {
    display: inline-block;
    margin-left: 20%;
    width: 80%;
}
.mainDiv {
    margin-top: 25px;
    padding: 0 30px;
}
.sidebaricons {
    height: 15px;
    width: 15px;
    margin-top: 10px;
}
.sidebarMenuDiv ul li:last-child {
    border-bottom: 0px;
}
.oneplaceHead {
    font-size: 18px;
    font-weight: 600;
    border-bottom: 2px solid #000;
    padding-bottom: 10px !important;
}
.contentdiv {
    margin-top: 30px;
}
.adminformdiv {
    width: 60%;
}
.admin-input-div label {
    font-size: 14px;
    margin-bottom: 5px;
}
.admin-input-div ::placeholder {
    font-size: 13px;
}
.overlay-main {
    height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.7);
      position: fixed;
      z-index: 2;
  }
  .spinnerCenter1 {
    margin: 0px auto;
    margin-top: 20%;
  }
  .defaultsectionheight {
    height: 100vh;
    background-color: #fff;
  }
.coursedetails-cardheader {
    cursor: pointer;
}
.modules-description {
    margin-left: 30px !important;
}
.carousel-indicators {
    margin-bottom: -3rem !important;
    // background-color: red;
}
.carousel-indicators button {
    background-color: rgba($color: #000000, $alpha: 0.2) !important;
    height: 4px !important;
}
.carousel-indicators .active {
    background-color: rgba($color: $navactivecolor, $alpha: 1.0) !important;
}
.trainer-carousel .carousel-control-prev-icon {
    background-image: url('../assets/left-arrow.png');
    opacity: 1;
}
.trainer-carousel .carousel-control-next-icon {
    background-image: url('../assets/left-arrow.png');
    opacity: 1;
}
.trainer-carousel  .carousel-control-prev, .trainer-carousel .carousel-control-next {
    opacity: 1;
    width: max-content;
}
.trainer-carousel .carousel-control-next {
    transform: rotate(180deg);
}
.trainer-breadscrumb-text {
    line-height: 42px;
}
.trainer-breadscrumb-pagetitle {
    font-size: 23px;
    margin-bottom: 30px !important;
}
.trainer-breadscrumb-description {
    font-size: 16px;
    color: #fff;
    margin-top: 30px;
}
.trainer-breadscrumb-btn {
    display: block;
    width: max-content;
    padding: 9px 20px;
    margin-top: 20px;
}
.trainerheadimg {
    height: 140px;
    margin-top: 80px;
    float: right;
}
.trainer-left-border {
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5);
    flex: 1;
}
.lefttrainerimg {
    height: 200px;
    margin: 0px auto;
    display: block;
}
.trainer-row-section {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    align-items: stretch;
    justify-content: space-between;
}
.trainer-left-border {
    max-width: 20%;
    border-radius: 15px;
    padding: 20px 0px;
}
.lefttrainer-name {
    margin-top: 15px;
    color: $navactivecolor;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
}
.lefttrainer-degree {
    margin-top: 10px;
    color: $navcolor;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
}
.trainer-right-border {
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5);
    height: auto;
    flex: 1;
    max-width: 77%;
    border-radius: 15px;
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.righttrainer-desc {
    color: $navcolor;
    font-size: 16px;
    font-weight: 600;
}
#inpersommodalid {
    width: 60%;
    height: 85%;
    margin: 0px auto;
}
.ReactModal__Overlay {
    z-index: 101;
    background-color: rgba(0, 0,0, 0.6) !important;
}
.modalheader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px;
}
.modaltitle {
    color: '#000';
    font-size: 16px;
    font-weight: 600;
}
.modalclosediv {
    cursor: pointer;
}
.coursecloseicons {
    font-size: 20px;
}
.modalformlabel {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px !important;
}
.modalbody {
    margin-top: 20px;
}
.modalsearchbtn {
    width: 100%;
    margin-top: 0px;
}
.batchesavailablehead {
    color: #000;
    font-size: 18px;
    font-weight: 600;
}
.resultsdiv {
    margin-top: 25px;
}
.popupdate {
    display: flex;
    flex-direction: row;
}
.popupdatetext {
    color: $navcolor;
    font-size: 14px;
    font-weight: 600;
    margin-left: 10px;
    margin-top: -3px;
}
.popupcountrytext {
    margin-left: 0px;
    margin-top: 0px;
}
.popupcountry {
    margin-top: 7px;
}
.inpersondisplayrow {
    border: 1px solid #cacaca;
    padding: 10px;
    border-radius: 5px;
    margin-top: 15px;
    font-size: 15px;
}
.popupdatetext .popupofferprice {
    margin-left: 5px;
    font-size: 14px;
}
.popupicons {
    font-size: 13px;
    color: $navcolor;
}
.popupdatetext1 {
    margin-top: 0px;
    margin-left: 5px;
}
.popupdatetext del {
    font-size: 12px;
}
.popupenrollbtn {
    height: 38px;
}
.nobatchestext {
    
}
.webadminmaindiv {
    background-color: $navactivecolor;
    text-decoration: none;
  }
  .webadminmaindiv:hover {
    text-decoration: none;
  }
  .tablebuttondivpage {
    // float: right;
    margin-bottom: 20px;
  }
  .tablebuttondiv {
    display: flex;
    justify-content: flex-end;
}
.btndiv {
    padding: 10px 15px;
    border-radius: 10px;
    text-decoration: none;
}
.btndiv p {
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    text-decoration: none;
}
.modal-title {
    font-size: 17px;
}
.webadmin-modalbtn {
    height: 40px;
    background-color: $navactivecolor;
    border-radius: 5px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $navactivecolor;
    cursor: pointer;
    width: max-content;
    padding: 0px 20px;
  }
  .webadmin-modalbtn p {
    color: #fff;
    text-align: center;
  }
  .webadmin-modalbtn:hover {
    background-color: #414141;
  }
  .tabinput {
    /* border: 1px solid #191919 !important; */
    border: 1px solid #191919 !important;
    }
    .webadmin-modalmainbody .tabinput {
        border: 1px solid rgba(0, 0, 0,0.5) !important;
        height: 40px;
    }
    .addproduct-formbox .tabinput {
        height: 40px;
      }
    .webadmin-modalmainbody label {
        margin-bottom: 5px;
    }
    .tabinput::placeholder {
        font-size: 14px;
    }
    .MuiPaper-root {
        width: 100%;
    }
    .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
        max-width: 80px !important;
    }
    .modaltitle {
        font-size: 19px !important;
    }
    .css-b62m3t-container {
        border: 1px solid rgba(0, 0, 0, 0.5) !important;
        border-radius: 5px;
    }
    .cartbackicon {
        color: $navactivecolor;
        font-size: 18px;
        margin-top: 3px;
    }
    .backtocoursetext {
        color: $navactivecolor;
        font-size: 16px;
        margin-left: 10px;
        text-decoration: none;
        font-weight: 600;
    }
    .cartbacklink {
        display: flex;
        flex-direction: row;
        text-decoration: none;
        cursor: pointer;
    }
    .cartbacklink:hover {
        text-decoration: none;
    }
    .backtocourse {
        margin-top: 30px;
    }
    .cartbox {
        background-color: #fff;
        padding: 20px 20px 30px;
        box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.2);
        margin-bottom: 30px;
    }
    .cartmaindiv {
        margin-top: 20px;
    }
    .ordersummary-box {
        display: flex;
        flex-direction: row;
    }
    .iconcircle {
        background-color: #d1d5db;
        border-radius: 50px;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        // padding: 15px 15px;
    }
    .iconcircle-icon {
        color: #6b7280;
        font-size: 19px;
    }
    .ordersummar-head {
        color: #6b7280;
        font-size: 18px;
        margin-left: 15px;
        font-weight: 600;
        margin-top: 7px;
    }
    .ordersummary-courserow {
        margin-top: 30px !important;
    }
    .ordersummary-coursebox {
        background-color: #fff;
        padding: 20px 20px 30px;
        box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
    }
    .ordersummary-course-head {
        font-size: 16px;
        font-weight: 600;
        color: #3c5487;
        width: 80%;
    }
    .ordersummary-course-hr {
        border: 1px solid #d5d8dc;
        margin: 20px 0px;
        opacity: 1;
    }
    .ordersummary-course-schedulehead {
        color: #3c5487;
        font-size: 16px;
        font-weight: 600;
    }
    .ordersummary-course-schedulediv {
        margin-top: 15px;
    }
    .ordersummary-course-schedulerow {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
    }
    .ordersummary-course-schedulerowkey {
        width: 40%;
        font-size: 14px;
        color: #3c5487;
        font-weight: 500;
    }
    .ordersummary-course-schedulerowvalue {
        margin-top: 0px;
        font-size: 14px;
        color: #3c5487;
        font-weight: 600;
    }
    .ordersummary-btn {
        font-weight: 600;
        height: max-content;
        border-radius: 10px;
        text-decoration: none;
        background-color: $navactivecolor;
        color: #fff;
        border: 1px solid $navactivecolor;
        width: 40%;
        margin-top: 30px;
        padding: 11px 0px;
        text-align: center;
        cursor: pointer;
    }
    .ordersummary-btn:hover {
        background-color: #fff;
        color: $navactivecolor;
    }
    .ordersummary-rightdiv {
        padding: 20px 50px;
    }
    .ordersummary-inputrow {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        justify-content: space-between;
    }
    .ordersummary-btn1 {
        font-weight: 600;
        border-radius: 5px;
        text-decoration: none;
        background-color: #3c5487;
        color: #fff;
        border: 1px solid #3c5487;
        padding: 0px 0px;
        width: 28%;
        height: 40px;
        text-align: center;
        cursor: pointer;
        // margin-left: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .ordersummary-btn1 p {
        font-size: 14px;
    }
    // .ordersummary-btn1:hover {
    //     background-color: #fff;
    //     color: #3c5487;
    // }
    .order-summaryinput {
        width: 68% !important;
        height: 40px;
        border: 1px solid #ccc !important;
    }
    .ordersummary-pricerow {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
    }
    .order-summaryinput::placeholder {
        font-size: 13px;
    }
    .ordersummary-pricerow {
        display: flex;
        flex-direction: row;
        margin-top: 15px;
        justify-content: space-between;
    }
    .ordersummary-totalprice {
        font-size: 15px;
        color: #3c5487;
        font-weight: 500;
        margin-top: 0px;
    }
    .ordersummary-totalprice1 {
        font-size: 16px;
        font-weight: 600;
    }
    .learnerdetails-maindiv {
        width: 60%;
        margin-top: 30px;
    }
    .learner-inputdiv .order-summaryinput {
        width: 100% !important;
        margin-top: 5px;
    }
    .learner-inputdiv {
        margin-bottom: 20px;
    }
    .learner-inputdiv .ordersummary-course-schedulerowkey {
        width: 100%;
    }
    .learnerdetails-maindiv .ordersummary-btn {
        margin-top: 15px;
    }
    .car-payment-text {
        font-size: 16px;
        margin-top: 25px;
    }
    .payment-box-maindiv {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
    }
    .payment-box {
        border: 1px solid #ccc !important;
        border-radius: 10px;
        padding: 50px 30px 30px;
        max-width: 30%;
        width: 30%;
        margin-right: 20px;
    }
    .paypal-logo {
        width: 70%;
        margin: 0px auto;
        display: block;
    }
    .payment-box .ordersummary-btn {
        width: 100%;
    }
    .paypal-bottom-text {
        width: 100%;
        margin-top: 15px;
        text-align: center;
    }
    .thankyouhead {
        color: $navactivecolor;
        font-size: 60px;
        font-weight: 800;
        margin-top: 30px;
        line-height: 75px;
    }
    .thankyousection {
        margin-top: 50px;
        margin-bottom: 100px;
    }
    .thankyouimg {
        height: 500px;
        margin: 0px auto;
        display: block;
    }
    .thankyoudesc {
        font-size: 19px;
        font-weight: 600;
        color: $navcolor;
        width: 90%;
        margin-top: 20px;
    }
    .thankyoudesc-needhelp {
        color: #333;
        font-size: 21px;
        font-weight: 700;
    }
    // .cartheader .headerdiv {
    //     box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
    // }
    .successcouponmsg {
        color: $btncolor;
        font-size: 12px;
        font-weight: 600;
        margin-top: 5px;
    }
    .failedcouponmsg {
        color: red;
        font-size: 12px;
        font-weight: 600;
        margin-top: 5px;
    }
    .editdetailsdiv {
        margin-left: auto;
        margin-top: 7px;
        cursor: pointer;
    }
    .editdetails-p {
        margin-left: auto;
        color: $navactivecolor;
        font-size: 16px;
        font-weight: 600;
    }
    #paypal-button-container {
        margin-top: 25px;
    }
    .singlecourse-trainerdiv {
        width: 60%;
        margin: 0px auto;
        display: block;
        margin-top: 30px;
        // margin-bottom: 50px;
    }
    .trainer-img {
        // width: 75%;
        border-radius: 10px;
        display: block;
        margin: 0px auto;
        margin-top: 70px;
        border: 1px solid #4a4a4a;
    }
    .trainer-rightdiv {
        margin-left: 10px;
    }
    .trainername {
        color: #1c2022;
        font-size: 19px;
        font-weight: 600;
        margin-bottom: 5px;
    }
    .trainerspecialize {
        font-size: 13px;
        margin-top: 2px;
        margin-left: 5px;
    }
    .singlecourse-overview-description {
        margin-top: 15px;
    color: #54595f;
    font-weight: 500;
    font-size: 15px;
    }
    .singlecourse-overview-description p+p {
        margin-top: 0px;
    }
    .trainer-imgcoursedetails {
        margin-top: 20px;
        width: 80%;
    }
    .coursedetails-navlink a:active,.coursedetails-navlink a:focus {
        color: #fff;
        text-decoration: none;
    }
    #registermodal {
        width: 30%;
        height: max-content;
        max-height: 90%;
        margin: 0px auto;
    }
    .registerbtn {
        width: 100%;
        background-color: $navactivecolor;
        border: 1px solid $navactivecolor;
        border-radius: 5px;
        height: 40px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-decoration: none;
        cursor: pointer;
    }
    .registerbtn p {
        color: #fff;
        text-decoration: none;
    }
    // .registerbtn:hover {
    //     background-color: #fff;
    // }
    // .registerbtn:hover p {
    //     color: $navactivecolor;
    // }
    .css-13cymwt-control {
        font-size: 12px;
    }
    .swal2-title {
        font-size: 20px;
    }
    .swal2-html-container {
        font-size: 16px !important;
    }
    .spinnerCenter {
        margin: 0px auto;
        display: block;
    }
    .course-btn:active, .course-btn:focus  {
        text-decoration: none;
    }
    .footerlinksdiv a:hover {
        text-decoration: none;
    }
    .privacycontent .choose-description {
        font-weight: 500;
    }
    .privacytophead {
        color: '#000';
        font-weight: 600;
        font-size: 19px;
        font-weight: 600;
        margin-top: 30px;
    }
    .privacyhead {
        color: '#000';
        font-weight: 600;
        font-size: 19px;
        font-weight: 600;
        margin-top: 30px;
    }
    .privacycontent  .privacyhead:first-child {
        margin-top: 0px;
    }
    .privacycontent  .privacytophead:first-child {
        margin-top: 0px;
    }

    .privacycontent ul .choose-description {
        font-weight: 400;
        margin-top: 5px;
    }
    .privacycontent ul .choose-description:first-child {
        margin-top: 15px;
    }

    .privacycontent ol .choose-description {
        font-weight: 400;
        margin-top: 5px;
    }
    .privacycontent ol .choose-description:first-child {
        margin-top: 15px;
    }
    .scheduleliheight {
        min-height: 315px;
    }
    .pmpfootermaindiv {
        padding: 0px 0px;
    }
    .pmpfootermaindiv .copyrightdiv {
        margin-top: 0px;
    }
    .feature-bluerow {
        display: flex;
        flex-direction: row;
        padding: 40px 0px;
    }
    .featurecoursedetailsicons {
        color: #fff;
        font-size: 28px;
    }
    .feature-bluerow-text {
        color: #fff;
        font-weight: 600;
        font-size: 18px;
        margin-left: 15px;
    }
    .feature-bluerow-parent {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .feature-bluerow-coursedetailsnavbar {
        position: unset;
    }
    .certificate-img-div {
        margin-top: 50px;
    }
    .certificate-img {
        width: 100%;
    }
    .allcourses-sidebar {
        background-color: #A4C8FF47;
        width: 100%;
        height: max-content;
        padding: 30px 30px;
        position: sticky;
        top: 80px;
    }
    .allcourses-categoryhead {
        color: $navactivecolor;
        font-size: 20px;
        font-weight: 800;
    }
    .categorysidebardiv {
        margin-top: 30px;
    }
    .categorysidebardivrow {
        display: flex;
        flex: row;
        padding: 4px 0px;
        margin: 10px 0px;
    }
    .allcourses-sidebartitle {
        color: $navcolor;
        font-size: 16px;
        margin-left: 10px;
        font-weight: 600;
        margin-top: 0px;
    }
    .allcourses-maindiv {
        padding: 30px 0px 30px 30px;
    }
    .resulttext {
        color: $navcolor;
        font-size: 16px;
        font-weight: 600;
        margin-top: -5px;
    }
    .resulttoprow {
        display: flex;
        flex-direction: row;
        padding-bottom: 12px;
        border-bottom: 1px solid $bordercolor;
    }
    .gridicondiv {
        border: 1px solid $navactivecolor;
        height: 40px;
        width: 40px;
        border-radius: 5px;
        margin-top: -18px;
        margin-left: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .gridiconstyle {
        font-size: 20px;
        color: $navcolor;
    }
    // .gridicondiv:hover {
    //     background-color: #A4C8FF47;
        
    // }
    .gridicondiv1 {
        margin-left: 10px;
    }
    .gridicondiv.active, .gridicondiv:hover {
        background-color: $navactivecolor;
        cursor: pointer;
    }
    .gridicondiv.active .gridiconstyle, .gridicondiv:hover .gridiconstyle {
        color: $colorwhite;
    }
    .nocoursetoshow {
        margin-top: 15px !important;
        font-size: 18px;
        font-weight: 500;
    }
    .schedulerow {
        display: flex;
        flex-direction: row;
        margin: 20px auto;
        justify-content: flex-start;
        // width: 60%;
    }
    .schedulecheckboxdiv {
        display: flex;
        flex-direction: row;
        width: 50%;
    }
    .schedulecheckboxdiv input[type="checkbox"] {
        transform: scale(1.2);
    }
    .schedule-inpersontitle {
        margin-left: 10px;
        font-weight: 600;
    }
    .coursecard-banner-btn-div {
        margin-top: 30px;
    }
    .pimautorizedtextdiv {
        display: flex;
        flex-direction: column;
    }
    .autorizedtextbg {
        background-color: #A4C8FF47;
        padding: 5px 20px 5px 20px;
        margin-top: 0px;
        margin-left: -15px;
        // width: max-content;
        text-align: center;
    }
    .autorizedtextbg p {
        margin-top: 0px;
    }
    .tplogodiv {
        // margin-right: 100px;
        margin-right: 0px;
    }
    .tplogo {
        height: 120px;
        margin-top: -20px;
        position: relative;
        z-index: 2;
    }
    .coursesloader {
        margin-top: 30px;
    }
    // toggle switch
    .coursecheckbox {
        margin-top: 5px;
    }
   .coursecheckbox .form-check-input[type=checkbox] {
    width: 18px;
    height: 18px;
   }
   .googleratingbelowflex {
    display: flex;
    margin-left: 15px;
   }
/*  course details header */
.coursedetailspage .headerdiv.is-sticky {
    position: unset;
}
.coursedetailspage .coursedetailsnavbar {
    top: 0px;
}
.offermodal {
    position: absolute;
    inset: 40px;
    // border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 15px;
    outline: none;
    padding: 0px;
    width: 50%;
    height: 500px;
    // max-height: 90%;
    margin: 0px auto;
    overflow: visible;
}
.offermodalinnerrow {
    display: flex;
    flex-direction: row;
}
.offerimgbg {
    // background-image: url('../assets/offerimg.jpeg');
    background-image: url('../assets/newofferbanner.jpeg');
    height: 500px;
    background-size: cover;
    background-position: center right;
    width: 45%;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    overflow: hidden;
}
.offerimg {
    height: 500px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    max-height: 500px;
}
.offermodalformdiv {
    min-height: 500px;
    height: 500px;
    max-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 30px;
    width: 55%;
    position: relative;
}
.offermodalformdiv .order-summaryinput {
    width: 100%;
    font-size: 12px;
}
.offerclaimbtn {
    background-color: #0ab67b;
    border: 1px solid #0ab67b;
}
.offerclaimbtn:hover {
    background-color: #fff;
}
.offerclaimbtn p {
    font-weight: 600;
}
.offerclaimbtn:hover p {
    color: #0ab67b;
}
.offermodalclosediv {
    z-index: 20;
    -webkit-box-shadow: -1px 2px 12px -3px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -1px 2px 12px -3px rgba(0, 0, 0, 0.75);
    box-shadow: -1px 2px 12px -3px rgba(0, 0, 0, 0.75);
    border-radius: 50%;
    background-color: white;
    opacity: 1;
    width: 50px;
    height: 50px;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    top: -15px;
    right: -15px;
}
.offermodalclosediv .coursecloseicons {
    font-size: 22px;
}
.offermodalfootertext p {
    font-size: 13px;
    margin-top: 15px;
    color: $navcolor;
}
.offermodalfootertext p a {
    color: $navcolor;
    text-decoration: none;
    font-weight: 600;
}
.offermodalfootertext p a:hover {
    color: #0ab67b;
}
.ccavenuebtnbg {
    background-color: #3f88cd;
    width: 100%;
    height: 45px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 35px;
}
.ccavenuebtnbg p {
    text-align: center;
    color: #fff;
}







































/* for desktop */
@media only screen and (min-width: 768px) {
    .deskhide {
      display: none;
    }
  }
  /* mobile */
  @media only screen and (max-width: 767px) {
    .pagewidth{
        width: 85%;
      }
     .mobhide {
      display: none;
     }
     .mobheadermaindiv {
        background-color: #fff;
        padding: 8px 0px;
        position: fixed;
          width: 100%;
          z-index: 10;
        top: 0px;
      }
      .rs-drawer-content {
        width: 65%;
        background-color: #fff;
      }
      .bannermaindiv {
        margin-top: 50px;
        height: 55vh;
      }
      .empowering-head {
        font-size: 24px;
      }
      .empowering-div {
        bottom: 50px;
      }
      .empowering-desc {
        font-size: 14px;
        width: 90%;
      }
      .course-btn {
        padding: 9px 14px;
      }
      .course-btn p {
        font-size: 12px;
      }
      .course-btn1 {
        margin-left: 15px;
      }
      .banneroverlaycolor {
        height: 55vh;
      }
      .section-head {
        font-size: 20px;
      }
      .home-section2, .home-section3, .home-section4, .home-section5, .home-section6, .footermaindiv {
        padding: 10% 0%;
    }
    .course-card-title {
        font-size: 16px;
        line-height: 23px;
    }
    .course-card-desc {
        font-size: 14px;
        line-height: 20px;
    }
    .features-div {
        margin-top: 0px;
    }
    .iconstyles {
        height: 14px;
    }
    .course-card-hr {
        margin: 20px 0px;
        margin-bottom: -10px;
    }
    .course-btn2 {
        margin-top: 15%;
    }
    .course-btn2 p {
        font-size: 14px;
    }
    .imprintsdiv {
        margin-top: 20px;
        width: 100%;
    }
    .imprintCount {
        font-size: 22px;
    }
    .imprintText {
        font-size: 14px;
    }
    .chooseimg {
        width: 40%;
        margin: 0px auto;
        display: block;
    }
    .choose-title {
        margin-top: 0px;
        font-size: 18px;
        text-align: center;
    }
    .choose-description {
        font-size: 14px;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 10px !important;
    }
    .why-upskills-div {
        margin-top: 10px;
    }
    .trainersmaindiv {
        flex-wrap: wrap;
        margin-top: 15px;
    }
    .trainerdiv {
        margin: 15px 0px;
    }
    .trainer-name {
        font-size: 17px;
    margin-top: 10px;
    }
    .trainer-img1 {
        height: 200px;
    width: 200px;
    }
    .testidiv {
        width: 90%;
        margin-top: 20px;
    }
    .testiname {
        font-size: 18px;
        margin-top: 20px;
    }
    .testicountry {
        font-size: 14px;
        margin-top: 3px;
    }
    .testitext {
        font-size: 14px;
    }
    .footerinnermaindiv {
        flex-wrap: wrap;
    }
    .footerdiv1 {
        width: 50%;
        margin-bottom: 30px;
    }
    .footerhead {
        font-size: 16px;
    }
    .footerlinksdiv {
        margin-top: 15px;
    }
    .footerlinksdiv a {
        font-size: 13px;
    }
    .copyrightdiv {
        margin-top: 0px;
    }
    .copyrighttext {
        font-size: 12px;
        padding: 30px 0px 0px !important;
    }
    .breadscrumb-text {
        font-size: 16px;
        line-height: unset;
    }
    .trainer-breadscrumb-description {
        font-size: 14px;
    }
    .trainerheadimg {
        display: none;
    }
    .breadscrumbg {
        margin-top: 66px;
        padding: 25px 0px;
    }
    .choose-description {
        text-align: left;
    }
    .about-us-description {
        width: 100%;
    }
    .about-us-img {
        width: 80%;
        margin: 0px auto;
        display: block;
        margin-top: 15px;
    }
    .about-left-div {
        padding: 15px;
    }
    .vision-subtext {
        width: 100%;
        font-size: 16px;
    }
    .about-right-div {
        margin-top: 30px;
    }
    .abouticons {
        height: 25px;
        width: 25px;
        margin-right: 10px;
    }
    .about-why-subhead {
        font-size: 16px;
        line-height: 20px;
    }
    .about-img2 {
        width: 80%;
        margin: 0px auto;
        margin-top: 30px;
        display: block;
    }
    .corporate-subtext {
        margin-top: 15px;
    }
    .corporate-breadscrumb-desc {
        margin-top: 20px;
        font-size: 14px;
    }
    .corporate-img1 {
        width: 90%;
        margin: 30px auto 30px;
    }
    .corporate-section1-head{
        line-height: unset;
    }
    .corporate-section1-description {
        margin-bottom: 30px !important;
        width: 100%;
    }
    .clientmaindiv {
        margin-top: 10px !important;
    }
    .breadscrumb-pagetitle {
        font-size: 18px;
        max-width: 100%;
    }
    .contactnumber {
        font-size: 14px;
    }
    .contacticons {
        width: 15px;
        height: 15px;
    }
    .contact-section1 .section-head {
        font-size: 18px;
    }
    .contact-info-div .contact-info-div1:last-child {
        margin-bottom: 30px;
    }
    .input-div {
        width: 100%;
    }
    .contactinput {
        height: 40px;
    }
    ::placeholder {
        font-size: 12px;
    }
    .course-details-topborder {
        margin-top: 50px;
    }
    .coursedetails-description {
        line-height: unset;
    }
    .section1-rating-div {
        margin-top: 25px;
    }
    .course-detail-img2 {
        height: 25px;
    }
    .course-detail-img1 {
        width: 90%;
        margin: 30px auto;
        display: block;
    }
    .coursedetails-featuresbg {
        padding: 15px 15px 30px;
    }
    .coursedetailsicons {
        font-size: 14px;
    }
    .features-right-div {
        padding: 20px 15px;
        margin-top: 30px;
    }
    .coursedetails-whicanattend {
        line-height: unset;
        margin-top: 15px;
    }
    .coursedetailsfeatures-row {
        margin-top: 5px;
    }
    .coursedetails-cardheader {
        padding: 10px 10px;
    }
    .coursedetailscardicons {
        font-size: 12px;
    }
    .coursedetails-cardheader p {
        font-size: 14px;
    }
    .coursedetails-cardbody p {
        line-height: unset;
        margin-left: 15px;
    }
    .coursedetails-card {
        margin-bottom: 5px;
    }
    .coursedetails-batchessubhead {
        margin-top: 20px;
    }
    .coursedetails-batcheshead {
        text-align: left;
    }
    .batches-boxdiv {
        margin-bottom: 30px;
    }
    .coursedetails-testiname {
        font-size: 18px;
    }
    .coursedetails-testimonialdiv {
        width: 85%;
    }
    .coursedetails-testicountry {
        font-size: 13px;
    }
    .inpersonbtn p {
        font-size: 15px;
    }
    .trainer-left-border {
        max-width: 90%;
        margin: 0px auto;
        display: block;
        padding: 25px 25px;
    }
    .trainer-right-border {
        display: none;
    }
    .lefttrainer-name {
        font-size: 18px;
    }
    .lefttrainer-degree {
        font-size: 12px;
    }
    .lefttrainerimg {
        height: 200px;
    }
    .righttrainer-desc {
        font-size: 14px;
        margin-top: 15px;
        font-weight: 500;
        text-align: left;
    }
    .moblogo img {
        height: 50px;
    }
    .mobmenubar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .logindiv {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    .mobmenu {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    .mobileheadericons {
        height: 20px;
        width: 20px;
    }
    .rs-drawer-body {
        width: 100%;
        padding: 0px 25px !important;
        margin-top: 30px !important;
    }
    .navbar-nav {
        display: block !important;
    }
    .rs-drawer-header {
        border-bottom: 0px !important;
    }
    .rs-drawer-header .rs-drawer-header-close {
        left: unset !important;
        right: 10px;
    }
    .rs-drawer-content {
        width: 80% !important;
    }
    .rs-drawer-left.rs-drawer-xs {
        width: 100% !important;
    }
    .rs-btn-icon.rs-btn-sm>.rs-icon {
        font-size: 18px !important;
    }
    .nav-link {
        color: $navcolor !important;
        font-weight: 600 !important;
        padding: 12px 0px !important;
        font-size: 15px !important;
    }
    .nav-item {
        border-bottom: 1px solid #e5e5e5;
    }
    .mobheadermaindiv {
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
    .dropdown-content {
        position: relative;
        min-width: unset !important;
        background-color: unset;
        box-shadow: unset;
    }
    .dropdown-content .dropdown-mainlist {
        padding: 0px 10px;
    }
    .dropdown-content .dropdown-mainlist .dropdown-mainlist-p {
        font-size: 12px;
        border-bottom: 1px solid #e5e5e5;
        max-width: 95%;
    }
    .headericons1 {
        margin-left: 0px;
    height: 12px;
    width: 12px;
    position: absolute;
    float: right;
    top: 14px;
    right: 0px;
    }
    .dropdown-content  {
        width: 100%;
        padding: 0px 0px 10px;
    }
    .nav-link {
        text-decoration: none;
    }
    .nav-link:active {
        text-decoration: none;
    }
    .subdropdowncontent {
        position: relative;
        left: 0px;
        // width: 100%;
        min-width: unset !important;
        background-color: unset;
        box-shadow: unset;
        padding: 10px 0px 10px 15px;
    }
    .dropdown {
        width: 100%;
    }
    .subdropdowncontentrow p {
        font-size: 12px;
    }
    .subdropdowncontentrow {
        font-size: 12px;
        background-color: unset;
        box-shadow: unset;
    }
    .nav-link:active {
        text-decoration: none;

    }   
    .nav-link:hover {
        text-decoration: none;
    }
    .dropdown.nav-item svg {
        float: right;
    } 
    #registermodal {
        width: 90%;
        inset: 15px !important;
        max-height: 100%;
    }
    .spinnerCenter1 {
        margin: 0px auto;
        margin-top: 25%;
        font-size: 20px !important;
    }
    #corporatetrainings {
        margin-top: 50px;
    }
    .singlecourse-trainerdiv {
        width: 80%;
        margin-top: 0px;
    }
    .trainer-imgcoursedetails {
        margin-top: 15px;
        width: 70%;
    }
    .trainer-rightdiv {
        margin-left: 0px;
    }
    .trainername {
        text-align: center;
    }
    .trainerspecialize {
        line-height: 18px;
    }
    .carousel-indicators {
        bottom: 5px !important;
    }
    #inpersommodalid {
        width: 90%;
        height: 90%;
        margin: 0px auto;
        inset: 20px !important;
    }
    .schedulerow {
        display: block;
    }
    .schedulecheckboxdiv {
        width: 100%;
    }
    .schedulerow .schedulecheckboxdiv:last-child {
        margin-top: 10px;
    }
    .modalbatchfiltersection .form-select {
        margin-bottom: 15px;
    }
    .schedulecheckmodalsearchbtn {
        margin-top: -15px;
    }
    .popupcountry {
        margin-top: 0px;
    }
    .popupdate {
        margin-top: 7px;
    }
    .popupenrollbtn {
        margin-top: 15px;
    }
    .trainer-carousel .carousel-indicators {
        margin-bottom: -2rem !important;
    }
    .home-section5, .home-section6 {
        padding: 10% 0% 15%;
    }
    .allcourses-maindiv {
        padding: 0px;
    }
    .mobproductsfinder .rs-drawer-content {
        right: 0;
        width: 65% !important;
    }
    .allcourses-sidebar {
        background-color: #fff;
        padding: 0px 0px;
    }
    .mobproductsfinder .rs-drawer-body {
        margin-top: 0px !important;
    }
    .mobproductsfinder .rs-drawer-header .rs-drawer-header-close {
        z-index: 10;
    }
    .categorysidebardiv {
        margin-top: 15px;
    }
    .allcourses-categoryhead {
        font-size: 17px;
    }
    .allcourses-sidebartitle {
        font-size: 15px;
    }
    .coursedetails-whicanattend-pointsidv {
        margin-top: 20px;
    }
    .batches-boxdiv {
        padding-bottom: 150px;
    }
    .tplogo {
        height: 120px;
        margin-top: -10px;
    }
    .tplogodiv {
        margin-right: 0px;
    }
    .section1-rating-div {
        display: block;
    }
    .section1-rating-div .coursedetails-googlediv {
        display: inline-block;
    }
    .section1-rating-div .course-detail-img2 {
        display: inline-block;
    }
    .section1-rating-div .section-head {
        display: inline-block;
    }
    .allcourses-banner-btn-div {
        margin-top: 30px;
    }
    .offermodal {
        width: 90%;
        inset: 15px;
        margin-top: 50px;
        height: max-content;
    }
    .offerimgbg {
        display: none;
    }
    .offermodalformdiv {
        width: 100%;
        height: max-content;
        max-height: unset;
        padding-bottom: 20px;
    }
    .yearendofferstext {
        margin-bottom: 15px !important;
        font-size: 18px;
        font-weight: 600;
    }
    .ordersummary-rightdiv {
        padding: 20px 0px;
    }
    .order-summaryinput {
        width: 65% !important;
    }
    .ordersummary-btn1 {
        width: 30%;
    }
    .learnerdetails-maindiv {
        width: 100%;
    }
    .ordersummary-btn {
        width: 100%;
    }
    .ordersummary-course-schedulerowkey {
        width: 100%;
    }
    .payment-box {
        width: 100%;
        max-width: 100%;
    }

    

    // .about-who-we-are {
    //     text-align: center;
    // }
  }

// for tab
@media only screen and (min-width: 600px) and (max-width: 767px) {

}
// for 90%
@media only screen and (min-width: 1600px) and (max-width: 1799px) {

}
/* for 80% */ 
@media only screen and (min-width: 1800px) {

}
/* for 125% */
@media only screen and (min-width: 960px) and (max-width: 1199px) {
    .pagewidth {
        width: 1050px;
    }
}
@media only screen and (min-width: 1200px) and (max-width: 1299px) {
    .pagewidth {
        width: 1100px;
    }
    .empowering-div {
        position: absolute;
        bottom: 100px;
    }
}
/* Tablet styles (landscape) */
@media only screen and (min-width: 768px) and (max-width: 960px) {
  
}

